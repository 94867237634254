import { Grid, Typography, makeStyles, Link } from "@material-ui/core";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
    background: "none",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
}));
const socials = [
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/wechat.png",
    link: "https://wa.me/message/DY37FMYCUSEJN1",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/instagramicon.png",
    link: "https://instagram.com/myauctions.jp?igshid=19dk8l9gy1v2j",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/linkedin.png",
    link: "https://www.linkedin.com/company/myauctions/",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/line logo.png",
    link: "https://lin.ee/2wg2q8j",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/fbicon.png",
    link: "https://www.facebook.com/myauctions.jp",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/zipang/whatsappicon.png",
    link: "https://wa.me/message/DY37FMYCUSEJN1",
  },
];
const StyledConinter = styled(Grid)`
  padding: 15px 20px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    text-align: center;
  }
`;

function Footer() {
  let { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {" "}
      <Grid
        id="CONTACT"
        container
        style={{
          marginTop: "0.4em",
          background: "#d92b3c",
          color: "white",
        }}
      >
        <StyledConinter container xs={12} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography style={{ textDecoration: "underline" }}>
              Japan office
            </Typography>

            <>
              <Typography className="fontLight">MJ Diamonds Co Ltd.</Typography>
              <Typography className="fontLight">
                {t("common:footer:officeAddress")}
              </Typography>
              <Typography className="fontLight">
                {t("common:footer:officeNumber")} |{" "}
                {t("common:footer:faxNumber")}
              </Typography>
            </>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography style={{ textDecoration: "underline" }}>
              Hongkong office
            </Typography>
            <>
              <Typography className="fontLight">
                Zipang Auctions HK Ltd
              </Typography>
              <Typography className="fontLight">
                {t("common:footer:zipangofficeAddress")}
                <br />
                {t("common:footer:zipangofficeAddresssecond")}
              </Typography>
            </>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography>Shobhit Suchanti</Typography>
            <>
              <Typography className="fontLight">+81-80552-47726</Typography>
            </>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography> {t("common:footer:emailId")}</Typography>
            <Link
              className={classes.link}
              href="/termsandconditions"
              style={{ fontFamily: "Playfair Display" }}
            >
              {t("common:register:termsandCondition")}
            </Link>
          </Grid>
        </StyledConinter>

        <div
          style={{
            textAlign: "center",
            width: "100%",
            fontSize: "1em",
            marginTop: '1em'
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              fontWeight: 400,
              fontSize: "0.8em",
              alignItems: "center",
            }}
          >
            {socials.map((social, index) => {
              return (
                <p>
                  <a
                    target="_blank"
                    href={index === 0 ? "https://d1xs39ogteakr9.cloudfront.net/zipang/weChat_QR.jpeg" : social.link}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      alt="social"
                      src={social.url}
                      style={{
                        maxHeight:
                          index === 1 || index === 4 || index === 5
                            ? "4vh"
                            : "3vh",
                      }}
                    />
                  </a>
                </p>
              );
            })}
          </div>
        </div>
      </Grid>
    </>
  );
}

export default Footer;
