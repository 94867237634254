import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledContainer = styled(Grid)`
  padding: 3em 0;
  background-color: ${(props) => {
    return props.color ? props.color : "white";
  }};
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.base.darkBlue};
  font-weight: bolder;
`;


export const StyledZiapngLink = styled(Link)`
  color: #d92b3c;
  font-weight: bolder;
  &:hover {
    color: #d92b3c;
  }
`;