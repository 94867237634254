import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from '../components/layouts/AppLayout';
import IRouterComponentProps from '../models/IRouterComponentProps';
import ProductFilter from '../components/products/preview/ProductsFilter';
import ProductsGrid from '../components/products/preview/ProductsGrid';
import { StyledButton } from '../common/materials/GenericStyleComponent';
import ApiTimeout from './ApiTimeout';
import ProductGridAfterFilter from '../components/products/preview/ProductGridAfterFilter';
import { searchAuctionFilterUrl, productTextSearch } from '../common/config/app.endpoints';
import AuthService from '../components/auth/AuthService';
import TabLayout from '../components/products/preview/TabLayout';
import ParcelItemFilter from '../components/products/preview/ParcelFilter';
import { buttonGradient } from '../common/materials/LinearGradient';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PubSub from 'pubsub-js';
import Jewellery from '../components/products/preview/Jewellery';
import HomePreviewOfallAuctions from "../components/products/preview/allCategoryAuctions/HomePreviewOfallAuctions";
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetItemPerPage, resetCurrentApiStartCount, resetJewelleryItemPerPage, resetJewelleryCurrentApiStartCount, reSetIsMybids, reSetJwlIsMybids } from '../redux/slice/commanPagination/commanPaginationSlice';
import { AppDispatch } from '../redux/store';

const StyledLayoutGrid = styled(Grid)`
    padding: 10px 50px;
    display: flex-wrap;
    overflow: auto;
    ::-webkit-scrollbar {display:none;};
    ${props => props.theme.breakpoints.between('xs', 'md')} {
        padding: 10px 10px;
    } 
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        padding: 10px 30px;
    }
`;

const StyledBoxContent = styled.div`
    width: 100%;
    text-align: right;
    height: 60px;
    margin-bottom: 20px;
`;

export const StyledButtonSearch = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    height: 42px;
    margin-bottom: 7px;
    margin-left: 5px;
    border: none;
    font-size: 1em;
    padding: 0.6em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    // background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;

const StyledBoxMainContainer = styled.div`
    width: 100%;
`;


interface IPreviewProps extends IRouterComponentProps {
  t: any;
}

const Preview = React.forwardRef((props: IPreviewProps) => {
  const [isFilteredAuctions, setIsFilteredAuctions] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredValues, setFilteredValues] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [isShowParcelItem, setShowParcelItem] = useState(false);
  const [isShowSearchBox, setShowSearchBox] = useState(true);
  const [isShowJewelley, setShowJewelley] = useState(false);

  const [isParcelFiltered, setIsParcelFiltered] = useState(false);
  const [filteredParcelValues, setFilteredParcelValues] = useState([]);
  const [isFilteredPrcelAuctions, setIsFilteredParcelAuctions] = useState([]);
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [isHomeCategoryActive, setIsHomeCategoryActive] = useState(true);
  const { t } = props;
  
  const Auth = new AuthService();
  const dispatch: AppDispatch = useDispatch();
  
  let history = useHistory();

    useEffect(() => {
      localStorage.setItem('urlRedirectFromEmail', window.location.href);
      localStorage.setItem("tabSelectedVal", "1");
      removeAndSetValue();     
      dispatch(resetItemPerPage());
      dispatch(resetCurrentApiStartCount());
      dispatch(resetJewelleryItemPerPage());
      dispatch(resetJewelleryCurrentApiStartCount());
      dispatch(reSetJwlIsMybids());
      dispatch(reSetIsMybids());
      var filteredValues: any = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
      localStorage.removeItem("filteredParcelValues")
      var filteredParcelValues: any = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
      
        if (filteredValues !== null) {
            filteredValues.map((item: any, index: number) => {
                if (item.name === "auctionId") {
                    filteredValues.splice(index, 1);
                }
            })

        } else if (filteredParcelValues !== null) {
            filteredParcelValues.map((item: any, index: number) => {
                if (item.name === "auctionId") {
                    filteredParcelValues.splice(index, 1);
                }
            })

    }
    const data = {
      "filters": {
        "and": filteredValues !== null ? filteredValues : filteredParcelValues
      },
      "page": {
        "start": 0,
        "count": 20,
        "sort": {
          "orders": [
            {
              "property": "serialNo",
              "direction": "ASC"
            }
          ]
        }
      }
    }
    let val = localStorage.getItem('optionValue')
    if (val !== "" && val !== 'null' && val !== null) {
      setOptionValue(val)
      autoCompleteSearch(val)
    } else {
      if (filteredValues !== null) {
        Auth.postRequest(searchAuctionFilterUrl, data, (status: any, response: any) => {
          if (status === 200) {
            setIsFiltered(true);
            setIsFilteredAuctions(response);
          }
        })
      } else if (filteredParcelValues !== null) {
        Auth.postRequest(searchAuctionFilterUrl, data, (status: any, response: any) => {
          if (status === 200) {
            setIsFiltered(true);
            setIsFilteredParcelAuctions(response);
          }
        })
      }
      else {
        setIsFiltered(false);
      }
    }
  }, [])

  useEffect(() => {
    if (
      history.location.pathname === "/preview" ||
      history.location.pathname === "/preview/home"
    ) {
      handleComponents("HOME");
    } else if (history.location.pathname === "/preview/diamond") {
      handleComponents("DIAMODS");
    } else {
      handleComponents("JEWELLERY");
    }
  }, [history.location.pathname]);

  const removeAndSetValue = () =>{
     localStorage.removeItem("filteredValues");
     localStorage.removeItem("urlRedirectFromAdmin");
     localStorage.removeItem("currentProductIdOfBidNow");
     localStorage.removeItem("currentJewelleryProductIdOfBidNow");
     localStorage.removeItem("currentPDItem");
     localStorage.removeItem("auctionUserId");
     localStorage.removeItem("userId");
     localStorage.removeItem("tenderitemsPerPage");
     localStorage.removeItem("itemsPerPage");
     localStorage.removeItem("filteredJewelleryValues");
     localStorage.removeItem("filteredValues");
     //new added localstorage
     localStorage.removeItem("itemPerPageSelected");
     localStorage.removeItem("activePageNumber");
     localStorage.removeItem("apiStartCount");
     localStorage.removeItem("jwlitemPerPageSelected");
     localStorage.removeItem("jwlactivePageNumber");
     localStorage.removeItem("jwlapiStartCount");
     localStorage.removeItem("dropDownFilterVal");
     localStorage.removeItem("annualdropDownFilterVal");
  }
  
  const filteredAuction = (auctions: any, isFilter: boolean, values: any) => {
    setIsFiltered(false);
    setFilteredValues(values);
    setIsFilteredAuctions(auctions);
    setFilteredParcelValues([]);
    setIsFilteredParcelAuctions([]);
    setIsFiltered(isFilter);
  }

  const filteredParcelAuction = (auctions: any, isFilter: boolean, values: any) => {
    setIsParcelFiltered(false);
    setFilteredParcelValues(values);
    setIsFilteredParcelAuctions(auctions);
    setIsFilteredAuctions([]);
    setFilteredValues([]);
    setIsParcelFiltered(isFilter)
  }

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  }

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      setApiTimeout(true)
    }
  }

  const tabItemClick = (value: String) => {
    setIsFiltered(false)
    setIsParcelFiltered(false)
    if (value === '2') {
      localStorage.setItem("tabSelectedVal", "2");
      setShowParcelItem(true);
    } else {
      localStorage.setItem("tabSelectedVal", "1");
      setShowParcelItem(false);
    }
  }

  const textSearchListing = (value: String) => {
    const data = {
      filters: {
        or: [
          {
            match: "anywhere",
            name: "remarks",
            value: value,
          },
          {
            match: "anywhere",
            name: "productDescription",
            value: value,
          }          
        ],
      },
    };

    if (value === "" || value.length === 1) {
      setTextSearchArray([])
    } else {
      if (value.length > 1) {
        Auth.postRequest(productTextSearch, data, (status: any, response: any) => {          
          if (status === 200) {
            setTextSearchArray(response.results)
          }
        })
      }
    }

  }

  const autoCompleteSearch = (val: any) => {    
    PubSub.publish("optionValue", val);
    localStorage.setItem("optionValue", val);
    setIsFiltered(false);
  }

  const handleComponents = (categoryType: String) => {
    if (categoryType === 'DIAMODS') {
      setShowJewelley(false);
      setIsHomeCategoryActive(false);
    } else if(categoryType === 'HOME'){
      setIsHomeCategoryActive(true);
      setShowJewelley(false);
    } else {
      setShowJewelley(true);
      setIsHomeCategoryActive(false);
    }
  }

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout {...props} />
      ) : (
        <StyledLayoutGrid
          container={true}
          spacing={0}
          className="carousalWrapper"
        >
          {/* <Grid item={true} xs={12}>
            <CategoryCarousal t={t} handleConponets={handleComponents} />
          </Grid> */}

          {isShowJewelley === true ? (
            <Jewellery {...props} />
          ) : (
            <StyledBoxMainContainer>
              <StyledBoxContent>
                {isShowSearchBox ? (
                  <div
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <div style={{ width: 300 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={textSearchArray}
                        onChange={(e: object, value: any | null) => {
                          if (value === "null" || value === null) {
                            setTextSearchArray([]);
                          }
                          setOptionValue(value);
                          autoCompleteSearch(value);
                        }}
                        value={optionValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("common:button:searchRemark")}
                            margin="normal"
                            variant="outlined"
                            size="small"
                            onChange={(e) => textSearchListing(e.target.value)}
                          />
                        )}
                      />
                    </div>

                    <StyledButtonSearch onClick={handleFilters}>
                      {t("common:button:advanceSearch")}
                    </StyledButtonSearch>
                  </div>
                ) : (
                  <StyledButton onClick={handleFilters}>
                    {t("common:button:basicSearch")}
                  </StyledButton>
                )}
              </StyledBoxContent>
              {isFiltersOpen === true && (
                <div>
                  <TabLayout {...props} t={t} tabClick={tabItemClick} />
                  {isShowParcelItem === false ? (
                    <ProductFilter
                      {...props}
                      t={t}
                      filteredAuctions={filteredAuction}
                      auctionId={null}
                      isPreviewPage={true}
                    />
                  ) : (
                    <ParcelItemFilter
                      {...props}
                      t={t}
                      filteredParcelAuctions={filteredParcelAuction}
                      auctionId={null}
                      isPreviewPage={true}
                    />
                  )}
                </div>
              )}
              {isHomeCategoryActive !== false ? (
                <HomePreviewOfallAuctions
                  {...props}
                  apiTimeout={timeout}
                  searchOptionValue={optionValue}
                />
              ) : (
                <>
                  {isFiltered === true || isParcelFiltered === true ? (
                    <Grid item={true} xs={12}>
                      <ProductGridAfterFilter
                        {...props}
                        apiTimeout={timeout}
                        filteredAuctions={isFilteredAuctions}
                        filteredValues={filteredValues}
                        filteredParcelValues={filteredParcelValues}
                        filteredParcelActions={isFilteredPrcelAuctions}
                      />
                    </Grid>
                  ) : (
                    <Grid item={true} xs={12}>
                      <ProductsGrid
                        {...props}
                        apiTimeout={timeout}
                        searchOptionValue={optionValue}
                      />
                    </Grid>
                  )}
                </>
              )}
            </StyledBoxMainContainer>
          )}
        </StyledLayoutGrid>
      )}
    </AppLayout>
  );
});

export default Preview;