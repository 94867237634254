import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

import { GlassMagnifier } from "react-image-magnifiers";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";

import { ParcelType, MediaType } from "../../../../enums/productEnum";
import VideoModal from "../../../shared/VideoModal";

import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  StyledArrowWrapper,
  StyledArrows,
  StyledCorouselWrapper,
  StyledCorouselWrapper365,
  StyledFrame,
  StyledGrid,
  StyledLargeText,
  StyledLargeTxtCon,
  StyledLargeTxtImg,
  StyledModal,
  StyledNotAvailableImage,
  StyledProductImage,
  StyledWrapperGrid,
} from "../../../../pages/preview/components/details/ProductCarouselStyles";
import ImageModal from "../../../shared/ImageModal";

interface IProps {
  productData: any;
  medias: any;
}

const ProductCarousel365 = (props: IProps) => {
  const { t } = useTranslation();
  const { medias, productData } = props;

  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    setShow(false);
    if (medias?.length === 1) {
      setTimeout(() => {
        setShow(true);
      }, 50);
    } else {
      setShow(true);
    }
  }, [medias]);

  // @ts-ignore
  const { parcelType } = { ...productData };

  const [onLargeVideo, setOnLargeVideo] = useState<boolean>(false);
  const [onLargeImage, setOnLargeImage] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );

  const videoLargePopup = (url: string) => {
    setVideoUrl(url);
    setOnLargeVideo(true);
  };

  const imageLargePopup = (url: string) => {
    setImageUrl(url);
    setOnLargeImage(true);
  };

  const onClose = () => {
    setOnLargeVideo(false);
    setOnLargeImage(false);
  };

  const isParcelTypeDiamond = () => {
    return parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeJewellery = () => {
    return parcelType === ParcelType.JEWELLERY;
  };

  const isMediaTypePhoto = (item: any) => {
    return item && item?.mediaType === MediaType.PHOTO;
  };
  const isMediaTypeVideo = (item: any) => {
    return item && item?.mediaType === MediaType.VIDEO;
  };

  const checkMedias = () => {
    return medias !== undefined && medias !== null && medias.length > 0;
  };

  const checkPresignedUrl = (item: any) => {
    return (
      (item && item?.thumbnailPresignedUrl !== null) ||
      item?.presignedUrl !== null
    );
  };

  const checkThumbnailPresignedUrl = (item: any) => {
    return (
      (item && item?.thumbnailPresignedUrl === null) ||
      item?.presignedUrl === null
    );
  };

  const renderArrowPrev = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => {
    return (
      <StyledArrowWrapper
        onClick={onClickHandler}
        title={label}
        style={{ left: 0 }}
      >
        <StyledArrows
          src={`/images/controls/${
            hasPrev
              ? "left_arrow_round_edges.png"
              : "left_arrow_round_edges_disabled.png"
          }`}
        />
      </StyledArrowWrapper>
    );
  };

  const renderArrowNext = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) => {
    return (
      <StyledArrowWrapper
        onClick={onClickHandler}
        title={label}
        style={{ right: 0 }}
      >
        <StyledArrows
          src={`/images/controls/${
            hasNext
              ? "right_arrow_round_edges.png"
              : "right_arrow_round_edges_disabled.png"
          }`}
        />
      </StyledArrowWrapper>
    );
  };

  const renderImageNotAvailable = () => {
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledNotAvailableImage>
          <StyledProductImage
            src={`/images/${
              isParcelTypeDiamond() || isParcelTypeJewellery()
                ? "default-diamond.png"
                : "default-parcel.png"
            }`}
          ></StyledProductImage>
        </StyledNotAvailableImage>
      </StyledGrid>
    );
  };

  const renderVideoNotAvailable = () => {
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledNotAvailableImage>
          <StyledProductImage src="/images/video_not_available.png"></StyledProductImage>
        </StyledNotAvailableImage>
      </StyledGrid>
    );
  };

  const renderFullScreenView = (item: any) => {
    return (
      <StyledLargeTxtCon item={true} xs={12}>
        <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
        <StyledLargeText
          onClick={() => videoLargePopup(`${item.presignedUrl}`)}
        >
          {t("common:preview:productDetail:viewFullScreen")}
        </StyledLargeText>
      </StyledLargeTxtCon>
    );
  };

  return (
    <>
      {checkMedias() ? (
        <StyledGrid container={true} spacing={0}>
          <StyledCorouselWrapper365>
            {show && (
              <Carousel
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                thumbWidth={60}
                transitionTime={0}
                swipeable={false}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
              >
                {medias.map((item: any, index: number) => (
                  <StyledWrapperGrid key={index}>
                    {isMediaTypePhoto(item) ? (
                      <div>
                        {checkPresignedUrl(item) ? (
                          <div
                            onClick={() => {
                              imageLargePopup(`${item.presignedUrl}`);
                            }}
                          >
                            <GlassMagnifier
                              imageSrc={`${item.thumbnailPresignedUrl}`}
                              magnifierSize="50%"
                              largeImageSrc={`${item.thumbnailPresignedUrl}`}
                            />
                          </div>
                        ) : (
                          renderImageNotAvailable()
                        )}
                      </div>
                    ) : (
                      <div>
                        {checkThumbnailPresignedUrl(item) ? (
                          renderVideoNotAvailable()
                        ) : (
                          <div>
                            {isMediaTypeVideo(item) ? (
                              <Grid container={true} spacing={0}>
                                {renderFullScreenView(item)}
                                <PlayerSlide url={`${item.presignedUrl}`} />
                              </Grid>
                            ) : (
                              <Grid container={true} spacing={0}>
                                {renderFullScreenView(item)}
                                {notSmallDevices ? (
                                  <StyledFrame
                                    src={`${item.presignedUrl}&sr=100`}
                                  />
                                ) : (
                                  <div id="new-wrapfreamDetailsCarousel">
                                    <StyledFrame
                                      id="new-scaled-frameDetailsCarousel"
                                      src={`${item.presignedUrl}&sr=100`}
                                      style={{ margin: "0px" }}
                                    />
                                  </div>
                                )}
                              </Grid>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </StyledWrapperGrid>
                ))}
              </Carousel>
            )}
          </StyledCorouselWrapper365>
        </StyledGrid>
      ) : (
        renderImageNotAvailable()
      )}
      {onLargeVideo && (
        <StyledModal open={onLargeVideo} onClose={onClose}>
          <VideoModal videoUrl={videoUrl} />
        </StyledModal>
      )}
      {onLargeImage && (
        <StyledModal open={onLargeImage} onClose={onClose}>
          <ImageModal imageUrl={imageUrl} onClose={onClose} />
        </StyledModal>
      )}
    </>
  );
};

export default React.memo(ProductCarousel365);
