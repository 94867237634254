import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import {
  StyledPriceContainer,
  StyledAmountInput,
  StyledPriceGridWrapper,
  StyledPriceTextInputDiv,
  StyledStartingPriceContainer,
} from "../../AuctionLivePageStyles";
import EllipsisText from "../../../../../common/elements/EllipsisText";
import { makeLinkClickable } from "../../../../../common/GenericFunctions";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

interface IProps {
  remarks: string;
  note: string;
  preBidAmount: any;
  conversionPreBidAmount: any;
  serialNo: number;
  per_id: string;
  parcelType: string;
  startingPrice: string;
}
let isClickableRemark: any;
const CardPriceSection = (props: IProps) => {
  const { t } = useTranslation();
  const {
    remarks,
    note,
    preBidAmount,
    conversionPreBidAmount,
    startingPrice, serialNo
  } = props;

  useEffect(() => {
    if (remarks !== undefined)
      isClickableRemark = makeLinkClickable(remarks);
  }, [serialNo])

  return (
    <Grid container={true} spacing={0} className="notesContainer">
      <StyledPriceGridWrapper item={true} xs={12}>
        <StyledStartingPriceContainer className="fontBold secondaryLabelFontSize">
          {t("common:home:auctionDetails:startingPricePerCarat")}-
        </StyledStartingPriceContainer>
        <StyledPriceTextInputDiv className="fontBold secondaryLabelFontSize">
          {startingPrice}
        </StyledPriceTextInputDiv>
      </StyledPriceGridWrapper>
      <StyledPriceGridWrapper item={true} xs={12}>
        <StyledStartingPriceContainer className="fontBold secondaryLabelFontSize">
          {t("common:home:auctionDetails:remarks")}-
        </StyledStartingPriceContainer>
        <StyledPriceTextInputDiv
          className="fontBold secondaryLabelFontSize"
          style={{ color: "red", display: 'flex', gap: '5px', flexDirection: 'row' }}
        >
          {isClickableRemark !== false && (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  isClickableRemark,
                  "DescriptiveWindowName",
                  "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                );
              }}
            >
              <PlayCircleOutlineIcon />
            </span>
          )}
          <span onClick={() => {
            if (isClickableRemark !== false) {
              window.open(
                isClickableRemark,
                "DescriptiveWindowName",
                "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
              );
            }
          }}>

            {remarks !== "" && remarks !== undefined && remarks !== null ? (
              <EllipsisText text={remarks} length={35} tail={"..."} />
            ) : (
              ""
            )}
          </span>
        </StyledPriceTextInputDiv>
      </StyledPriceGridWrapper>
      <StyledPriceGridWrapper
        item={true}
        xs={12}
        style={{ color: "lightgrey" }}
      >
        <StyledStartingPriceContainer
          className="fontBold secondaryLabelFontSize"
          style={{ color: "grey" }}
        >
          {t("common:home:auctionDetails:notes")}-
        </StyledStartingPriceContainer>
        <StyledPriceTextInputDiv
          className="fontBold secondaryLabelFontSize"
          style={{ color: "grey" }}
        >
          <EllipsisText text={note} length={35} tail={"..."} />
        </StyledPriceTextInputDiv>
      </StyledPriceGridWrapper>
      <StyledPriceContainer
        item={true}
        xs={12}
        style={{ textAlign: "initial" }}
      >
        <StyledStartingPriceContainer
          className="fontBold secondaryLabelFontSize"
          style={{ color: "grey" }}
        >
          {t("common:home:auctionDetails:preBiddingPrice")}-
        </StyledStartingPriceContainer>
        <StyledAmountInput
          className="fontBold secondaryLabelFontSize"
          style={{ color: "grey" }}
        >
          {preBidAmount} {conversionPreBidAmount}
        </StyledAmountInput>
      </StyledPriceContainer>
    </Grid>
  );
};

export default CardPriceSection;
